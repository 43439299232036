import { useMemo } from "react";

import { QueryArgs } from "../../types/context";

export function getQueryId(
  queryName: string,
  queryArgs: QueryArgs = {}
): string {
  const stringified = Object.fromEntries(
    Object.entries(queryArgs).map(([key, value]) => [
      key,
      typeof value === "object" ? JSON.stringify(value) : String(value),
    ])
  );
  const stringifiedArgs = new URLSearchParams(stringified).toString();
  return `${queryName}${stringifiedArgs.length ? `?${stringifiedArgs}` : ""}`;
}

export function useQueryId(queryName: string, queryArgs: QueryArgs): string {
  return useMemo(
    () => getQueryId(queryName, queryArgs),
    [queryName, queryArgs]
  );
}
