import { useCallback } from "react";

import { AtomSetterAction } from "../../types/atom";
import { useAtomStore } from "../atom-store";

export default function useSetAtom(): <T>(
  atomId: string,
  value: AtomSetterAction<T>
) => void {
  const store = useAtomStore();
  return useCallback(
    <T>(atomId: string, value: AtomSetterAction<T>) => {
      store.update(atomId, value);
    },
    [store]
  );
}
