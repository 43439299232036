import { useContext } from "react";

import { AtomStore } from "./atomStore";

export default function useAtomStore(): AtomStore {
  const store = useContext(AtomStore.context);
  if (!store) {
    throw new Error(
      "[@mobsuccess-devops/react-atom] AtomStore: AtomStore context not found!\n" +
        "You should wrap your app with <AtomStoreProvider>."
    );
  }
  return store;
}
